var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "explain" }, [
    _c("header", { staticClass: "title" }, [_vm._v("结算")]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "icon" }),
      _c("h3", [_vm._v("结算申请提交成功")]),
      _c("p", [_vm._v("我们将尽快为您审核,请留意短信通知")]),
      _c(
        "div",
        { staticClass: "checkMarket", on: { click: _vm.checkMarket } },
        [_vm._v(" 查看行情 ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }